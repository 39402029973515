
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }

    .el-link + .el-link {
        margin-left: 10px;
    }

    .school-logo {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .logo-uploader {
        width: 100px;
        height: 100px;
        border: 1px solid #ddd;

        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .logo-cover {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        .logo-uploader-icon {
            color: #ccc;
            font-size: 24px;
        }
    }

    .school-prompt {
        color: #F56C6C;
    }

    ::v-deep .template-dialog {
        .el-dialog__body {
            padding: 30px;
        }
    }

    .template-list {
        margin-top: -10px;

        .el-checkbox-group {
            max-height: 200px;
            min-height: 100px;
        }

        ::v-deep .el-checkbox {
            margin-top: 10px;

            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                border-color: #1122D8;
                background-color: #1122D8;
            }

            .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #1122D8;
            }
        }
    }
